<!--
 * @Author: lbh
 * @Date: 2021-04-28 17:16:18
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-30 09:35:41
 * @Description: GSQ 自助排隊系統
-->
<template>
  <div
    class="gsq-box t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="products"
      child='gsq'
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="GSQ 自助排隊系統"
        :type="3"
        :wtype="window.type"
        :image="getImgPath('pbanner8.jpg')"
        :bgImage="getImgPath('b3-1.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        title="GSQ 自助排隊系統"
      />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        height: 0
      }
    }
  },
  mounted () {
    util.setTitle('GSA 手機點餐系統 – 薑軟件有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 初始化數據
    let imgs = [util.getImgPath('waiting01.png')];
    this.graphic.imgs = imgs;
    let datas = [
      {
        title: '自助取票端', items: [
          { title: '– 客人自助輸入人數取票' },
          { title: '– 系統自動分隊' },
          { title: '– 入座SMS短訊提示功能' },
          { title: '– 自定SMS短訊發送間距' },
          { title: '– 輸入特定以上人數特別提示' },
          { title: '– 鎖屏功能（防止客人在非營業時間內取票）' },
        ],
      },
      {
        title: '號碼 / 廣告顯示端', items: [
          { title: '– 介面排版靈活' },
          { title: '– 支援多個廣告面板' },
          { title: '– 列隊模式, 多號模式' },
          { title: '– 支援多屏不同排版' },
          { title: '– 支援圖片, 影片, 文字播放' },
          { title: '– 獨立設置不同時段及假期的廣告播放' },
        ],
      },
      {
        title: '叫號端', items: [
          { title: '– 簡潔介面, 操作簡單' },
          { title: '– 支援員工取票功能' },
          { title: '– 系統自動選取叫號號碼, 一鍵叫號' },
          { title: '– 提供入座數據報表' },
        ],
      },
    ];
    this.graphic.datas = datas;
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.2);
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 1.4;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
        this.graphic.height = (w * 0.8 - 40) * 1.4;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 1.4;
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
